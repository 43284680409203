<template>
	<div class="flex-1 bg-white pa-24 radius-20 mb-30">
		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">{{  program.title }}</h3>
		</div>

		<div
			class="pa-10-20"
		>
			<DatePicker class="mt-20" type="year" :date="subscrp_year" @click="setDate($event)" ></DatePicker>
			<div
				class="mt-30"
			>
				<ul
					v-if="items_list.length > 0"
					class="size-px-14"
				>
					<li
						v-for="(history, h_index) in items_list"
						:key="'history_' + h_index"
						class="under-line-not-last pb-20 mb-20 cursor-pointer"
						@click="onDetail(history)"
					>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>닉네임</div>
							<div class="size-px-16 font-weight-500">{{ history.nickname }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>구독상품</div>
							<div class="font-weight-500">{{ history.subscrp_plan_name }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>결제수단</div>
							<div class="font-weight-500">{{ history.payment_mthd_name }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>결제수량</div>
							<div class="font-weight-500">{{ history.amount | makeComma}}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>거래일시</div>
							<div class="font-weight-500">{{ history.pmtdate }}</div>
						</div>
					</li>
				</ul>

				<Empty
					v-else
				></Empty>
			</div>
		</div>

		<PopupLayer
			v-if="is_on_detail"
		>
			<template
				v-slot:body
			>
				<div
					class="width-480 ma-auto bg-white flex-column justify-space-between radius-20"
				>
					<div class="size-px-14">
						<div class="size-px-20 text-center font-weight-bold under-line pb-20 
						bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20">
							<h4 class="color-white">
								결제 상세내역
							</h4>
							<img class="cursor-pointer"
							@click="offDetail"
							:src="require('@/assets/image/web/notify_cartel/ico_close.svg')"/>
						</div>

						<div class="bg-white pa-30">
							<div class="">
								<h5 class="font-weight-700">정보</h5>
								<div class="justify-space-between text-left mt-15">
									<div>닉네임</div>
									<div class="font-weight-500">{{ item.nickname }}</div>
								</div>
								<div class="justify-space-between text-left mt-10">
									<div>구독상품</div>
									<div class="font-weight-500">{{ item.subscrp_plan_name }}</div>
								</div>
							</div>
						<div class="mt-20 top-line pt-20">
							<h5 class="font-weight-700">결제정보</h5>
							<div class="justify-space-between text-left mt-15">
								<div>거래번호</div>
								<div class="font-weight-600">{{ item.payment_number }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>승인번호</div>
								<div class="font-weight-600">{{ item.leglter_payment_approval_number }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>결제수단</div>
								<div class="font-weight-600">{{ item.payment_mthd_name }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>결제수량</div>
								<div class="font-weight-600">{{ item.amount | makeComma }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>거래일시</div>
								<div class="font-weight-600">{{ item.pmtdate }}</div>
							</div>
						</div>

						<div class="mt-30 flex-row justify-center">
							<button
								@click="offDetail"
								class="bg-primary pa-13-30 color-white size-px-14 font-weight-500 radius-20 line-height-1"
							>목록으로</button>
							</div>
						</div>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<script>

import PopupLayer from "@/view/Layout/PopupLayer";
import DatePicker from "@/components/DatePicker";
import Empty from "@/view/Layout/Empty";

export default {
	name: 'SubscribeHistory'
	, components: {Empty, DatePicker, PopupLayer}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'payment list'
				, title: '결제내역'
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
				, from: 'mafia049'
				, bg_contents: 'bg-gray01'
				, cartel: ''
				, is_side: true
			}
			, item: {
				point: 0
			}
			, items: [
				{ subscrp_member_number: '1234', payment_coin_quantity: 100, type: '+', nickname: '충전', payment_mthd_name: 'card', subscrp_plan_name: '신용카드', pmtdate: '2023.06.01 18:20:11'}
				, { subscrp_member_number: '1234', payment_amount: 100, type: '+', nickname: '이벤트', payment_mthd_name: 'card', subscrp_plan_name: '오픈 이벤트', pmtdate: '2023.06.01 18:20:11'}
				, { subscrp_member_number: '1234',  payment_amount: 100, type: '-', nickname: '구독', payment_mthd_name: 'card', subscrp_plan_name: '플랜 구독', pmtdate: '2023.06.01 18:20:11'}
				, { subscrp_member_number: '1234',  payment_amount: 100, type: '-', nickname: '후원', payment_mthd_name: 'card', subscrp_plan_name: '카르텔 후원', pmtdate: '2023.06.01 18:20:11'}
				, { subscrp_member_number: '1234',  payment_amount: 100, type: '+', nickname: '충전', payment_mthd_name: 'card', subscrp_plan_name: '인앱', pmtdate: '2023.06.01 18:20:11'}
				, { subscrp_member_number: '1234',  payment_amount: 100, type: '+', nickname: '충전', payment_mthd_name: 'card', subscrp_plan_name: '신용카드', pmtdate: '2023.06.01 18:20:11'}
			]
			, is_on_detail: false
			, subscrp_year: this.$date.init().year
		}
	}
	, computed: {
		items_list: function(){
			return this.items.filter((item) => {
				if(item.payment_coin_quantity > 0){
					item.amount = item.payment_coin_quantity
				}else if(item.last_payment_amount > 0 ) {
					item.amount = item.last_payment_amount
				}else{
					item.amount = item.payment_amount
				}
				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_history
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, subscrp_member_number: this.$route.params.m_id
						, subscrp_year: this.subscrp_year + ''
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.payment_particulars_list
					if(this.items.length > 0){
						this.item_last = this.items[this.items.length - 1]
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPayment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_request_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPayment: function(item){
			this.is_on_payment = true
			this.item_payment = item
		}
		, offPayment: function(){
			this.is_on_payment = false
			this.item_payment = {}
		}
		, to: function(type){
			this.$bus.$emit('to', { name: 'PointHistory', params: { type: type}})
		}
		, onDetail: function(item){
			this.is_on_detail = true
			this.item = item
		}
		, offDetail: function(){
			this.is_on_detail = false
			this.item = {}
		}
		, setDate: function(date){
			this.subscrp_year = date
			this.getData()
		}
		, getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.program.cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.getCartel()
		this.$emit('onLoad', this.program)
		this.getData()

		this.$bus.$off('callBack')
		this.$bus.$on('callBack', (call) => {
			if(call.name == 'to_payment_history'){
				this.$emit('to', { name: 'PaymentHistory'})
			}
		})
	}
}
</script>
